import React, { useState, useEffect, useRef } from 'react';
import SkeletonLoader from '../animations/SkeletonLoader';

const LazyShortsImage = ({ img }) => {
    const [inView, setInView] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1, // Trigger when 10% of the image is in view
            }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    return (
        <div style={{ position: 'relative', display: 'flex', minWidth: '60px' }} ref={imgRef}>
            {inView && (
                <>
                    {loading && (
                        <SkeletonLoader style={{ width: '100%', minWidth: "150px", maxWidth: "181px", height: '100%', minHeight: "300px", maxHeight: "310px", borderRadius: '15px' }} />
                    )}
                    <img
                        loading="lazy"
                        style={{ borderRadius: '15px', marginRight: '10px', width: '100%', maxWidth: "181px", height: "300px", minHeight: "300px", maxHeight: "310px", visibility: loading ? "hidden" : "visible" }}
                        src={img}
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)} // Optional: handle error state
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(LazyShortsImage);
