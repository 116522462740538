import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAllPodcasts, podcastViewed } from "../../apiCalls/podcasts/api_v1_podcasts";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import guidezIcon from "../../static/img/guidez-icon.svg";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import LazyPodcastsImg from "../LazyImage/LazyPodcasts";
const Podcasts = ({ gameId, isMobile }) => {
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  let cancelTokenSource;

  useEffect(() => {
    const fetchPodcasts = async () => {
      setLoading(true);
      try {
        cancelTokenSource = axios.CancelToken.source();
        let payload = {};
        if (location.pathname !== "/home") {
          payload = { uuid: gameId };
        }
        const response = await getAllPodcasts(payload, {
          cancelToken: cancelTokenSource.token,
        });
        if (response.status === 200 && response.data.success === true) {
          setPodcasts(response.data.data.podcasts);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled:", error.message);
        } else {
          // console.error("Error fetching podcasts:", error);
        }
      }
    };

    fetchPodcasts();

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [gameId, location.pathname]);

  const getPodcastViewed = (podcast) => {
    // podcastViewed(podcast.uuid)
    //   .then((res) => {
    // if (res.status === 200 && res.data.success === true) {
    // console.log(res.data.data);
    navigate(`/podcast-detail?uuid=${podcast.uuid}`, { state: { podcast: podcast } });
    // }
    // })
    // .catch((e) => {
    //   console.log(e);
    // });
  };

  return (
    <div style={{ height: "100%", minHeight: "350px", maxHeight: "380px", marginBottom: "15px", overflow: "hidden" }}>
      <div className="horizontal-header">
        <img src={guidezIcon} alt="Guidez Icon" className="bitez-icon" />
        <h3>Gamez Guides</h3>
      </div>
      {podcasts.length > 0 ? (
        <section>
          {!loading ? (
            <>
              <Swiper
                spaceBetween={60}
                slidesPerView={isMobile ? 1 : 2.1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
              >
                {podcasts.map((podcast, index) => (
                  <SwiperSlide key={index}>
                    <div
                      key={index}
                      onClick={() => getPodcastViewed(podcast)}
                      style={{ textDecoration: 'none', cursor: "pointer" }}
                    >
                      <LazyPodcastsImg img={podcast.thumbnail} title={podcast.title} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

            </>
          ) : (
            <Spinner />
          )}
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default Podcasts;
