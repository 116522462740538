import React, { useState, useEffect } from "react";
import HomeTab from "../tabs/HomeTab";
import ShortsTab from "../tabs/ShortsTab";
import ProfileTab from "../tabs/ProfileTab";
import GamesTab from "../tabs/GamesTab";
import MenuBar from "../components/MenuBar";
import BottomMenu from "../components/BottomMenu";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GetStarted from "./GetStarted";
import PrivateRoute from "../components/PrivateRoute";
import GameDetails from "../tabs/GameDetails";
import PodcastDetails from "../tabs/PodcastDetails";
import { reLogin, verifyToken } from "../apiCalls/jwt/api_v1_jt";
import SideMenu from "../components/SideMenu";
import EditProfileModule from "../tabs/EditProfileModule";
import Unsub from "../components/unsub";
import StoryViewer from "../components/stories/StoryViewer";

const MainScreen = () => {
  let path = window.location.pathname;
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 520);
  const [demo, setDemo] = useState();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 520);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await verifyToken();
        if (response?.status === 200) {
          setLoggedIn(true);
          return;
        } else {
          setLoggedIn(false);
          hanldeLogin();
        }
      } catch {
        setLoggedIn(false);
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {
    const demoExists = localStorage.getItem("demo");
    if (demoExists) {
      setDemo(true);
    } else {
      setDemo(false);
    }
  }, []);

  function hanldeLogin() {
    let isTokenAvailable = !!localStorage.getItem("token");
    if (!isTokenAvailable) {
      return (window.location.href = process.env.REACT_APP_LOGIN_URL);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BrowserRouter>
        {demo ? (
          <>
            {isMobile ? <MenuBar isMobile={isMobile} /> : <></>}
            {isMobile ? <BottomMenu /> : <SideMenu />}
          </>
        ) : (
          <></>
        )}
        <div className={`${!isMobile && demo && path != "/shorts" ? "content-active" : ""}`}>
          <Routes>
            <Route
              path="/home"
              element={<HomeTab isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} isMobile={isMobile} />}
            />
            <Route element={<PrivateRoute />}>
              {demo ? (
                <Route path="/" element={<HomeTab isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} isMobile={isMobile} />} />
              ) : (
                <Route path="/" element={<GetStarted />} />
              )}
              <Route
                path="/games"
                element={<GamesTab isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} />}
              />
              <Route
                path="/game-detail"
                element={<GameDetails isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} isMobile={isMobile} />}
              />
              <Route
                path="/podcast-detail"
                element={<PodcastDetails isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} isMobile={isMobile} />}
              />
              <Route
                path="/shorts"
                element={<ShortsTab isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} />}
              />
              <Route
                path="/profile"
                element={<ProfileTab isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} />}
              />
              <Route
                path="/edit-profile"
                element={<EditProfileModule isLoggedIn={isLoggedIn} handleLogin={hanldeLogin} />}
              />
              <Route path="/unsubscribe" element={<Unsub />} />
              <Route path='/story' element={<StoryViewer />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
      {path !== "/shorts" && demo ? (
        <div id="bbox"></div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MainScreen;
