import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HomeSVG from "../static/img/bottom-menu/HomeSVG";
import GameSVG from "../static/img/bottom-menu/GameSVG";
import ShortsSVG from "../static/img/bottom-menu/ShortsSVG";
import ProfileSVG from "../static/img/bottom-menu/ProfileSVG";
import "../static/css/sideMenu.css";
import MenuBar from "./MenuBar";

const SideMenu = () => {
  const [activeButton, setActiveButton] = useState("home");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setActiveButton("home");
        break;
      case "/games":
      case "/game-detail":
      case "/podcast-detail":
        setActiveButton("games");
        break;
      case "/shorts":
        setActiveButton("shorts");
        break;
      case "/profile":
      case "/edit-profile":
        setActiveButton("profile");
        break;
      default:
        setActiveButton("");
    }
  }, [location]);

  const handleClick = (tabName) => {
    setActiveButton(tabName);
    navigate(`/${tabName}`);
  };

  return (
    <div className="side-menu">
      <MenuBar />
      <ul>
        <li
          className={`list ${activeButton === "home" ? "active" : ""}`}
          onClick={() => handleClick("home")}
        >
          <span className="menu-item">
            <span className="icon">
              <HomeSVG color={activeButton === "home" ? "#2272A8" : "white"} />
            </span>
            <span className="text">Home</span>
          </span>
        </li>
        <li
          className={`list ${activeButton === "games" ? "active" : ""}`}
          onClick={() => handleClick("games")}
        >
          <span className="menu-item">
            <span className="icon">
              <GameSVG color={activeButton === "games" ? "#2272A8" : "white"} />
            </span>
            <span className="text">Games</span>
          </span>
        </li>
        <li
          className={`list ${activeButton === "shorts" ? "active" : ""}`}
          onClick={() => handleClick("shorts")}
        >
          <span className="menu-item">
            <span className="icon">
              <ShortsSVG
                color={activeButton === "shorts" ? "#2272A8" : "white"}
              />
            </span>
            <span className="text">Shorts</span>
          </span>
        </li>
        <li
          className={`list ${activeButton === "profile" ? "active" : ""}`}
          onClick={() => handleClick("profile")}
        >
          <span className="menu-item">
            <span className="icon">
              <ProfileSVG
                color={activeButton === "profile" ? "#2272A8" : "white"}
              />
            </span>
            <span className="text">Profile</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
