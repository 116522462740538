import React, { useEffect, useRef, useState } from "react";
import profile_img_ph from "../static/img/profile-tab/player_img.png";
import logosTwitter from "../static/img/logos_twitterX.svg";
import logosFacebook from "../static/img/logos_facebook.svg";
import logosSkype from "../static/img/logos_skype.svg";
import logosYoutube from "../static/img/logos_youtube.svg";
import { getProfile } from "../apiCalls/user_profile/api_v1_profile";
import { reLogin } from "../apiCalls/jwt/api_v1_jt";
import { Link, useNavigate } from "react-router-dom";
import editIcon from "../static/img/edit_icon.svg";
import { Typography } from "@mui/material";

const ProfileTab = ({ isLoggedIn, hanldeLogin }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Profile | Gamez Academy';
    async function fetchData() {
      try {
        const cachedProfile = JSON.parse(localStorage.getItem("profileData"));
        if (cachedProfile) {
          setProfileData(cachedProfile);
          setTimeout(async () => {
            const response = await getProfile();
            localStorage.setItem(
              "profileData",
              JSON.stringify(response.data.data)
            );
            setProfileData(response.data.data);
          }, 3000);
        } else {
          const response = await getProfile();
          localStorage.setItem(
            "profileData",
            JSON.stringify(response.data.data)
          );
          setProfileData(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        // console.log(error);
        return reLogin();
      }
    }
    fetchData();
  }, []);

  return (
    <div className="tab">
      <header>
        <div className="profile-container">
          <div className="profile-icon">
            <img
              width={45}
              height={45}
              src={editIcon}
              id="edit-icon"
              onClick={() => navigate("/edit-profile")}
            />
            <img className='prof-img' src={profileData?.profileImage ? profileData?.profileImage : profile_img_ph} alt="Profile Icon" />
          </div>
          <div className="player-info">
            <h2>{profileData?.username}</h2>
            <p>
              {profileData?.city && profileData.city + ","}
              {profileData?.country}
            </p>
          </div >
        </div>
        <center>
          <button
            className="unsub-btn ripple"
            onClick={() => {
              localStorage.removeItem('token');
              localStorage.removeItem('profileData');
              window.location.replace("https://lp.gamezacademy.pk/");
            }}
          >
            Logout
          </button>
        </center>
      </header>
      <section id="player-bio">
        <h2>Bio</h2>
        <Typography variant="body1">
          {profileData?.bio}
        </Typography>
      </section>
      <section id="other-info">
        <h2>Other Info</h2>
        <div className="info-table">
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Gender:</b>
                </td>
                <td>{profileData?.gender}</td>
              </tr>
              <tr>
                <td>
                  <b>Email:</b>
                </td>
                <td>{profileData?.email}</td>
              </tr>
              <tr>
                <td>
                  <b>Date of Birth:</b>
                </td>
                <td>{profileData?.dateOfBirth}</td>
              </tr>
              <tr>
                <td>
                  <b>Join Date:</b>
                </td>
                <td>{profileData?.joinDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section id="user-social">
        <h2>Social Profiles</h2>
        <br />
        {profileData && (
          <div className="social-icons">
            {profileData?.social_facebook && (
              <Link to={`https://www.facebook.com/${profileData?.social_facebook}`}>
                <img src={logosFacebook} alt="Facebook" />
              </Link>
            )}
            {profileData?.social_twitter && (
              <Link to={`https://www.twitter.com/${profileData?.social_twitter}`}>
                <img src={logosTwitter} alt="Twitter" />
              </Link>
            )}
            {profileData?.social_skype && (
              <Link to={`https://www.skype.com/${profileData?.social_skype}`}>
                <img src={logosSkype} alt="Skype" />
              </Link>
            )}
            {profileData?.social_youtube && (
              <Link to={`https://www.youtube.com/${profileData?.social_youtube}`}>
                <img src={logosYoutube} alt="Youtube" />
              </Link>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default ProfileTab;
