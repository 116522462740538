import React from 'react';

const ProfileSVG = ({ color, bg }) => (
    <svg width="40" height="41" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 0.5C38.8071 0.5 50 11.6929 50 25.5V25.5C50 39.3071 38.8071 50.5 25 50.5V50.5C11.1929 50.5 0 39.3071 0 25.5V25.5C0 11.6929 11.1929 0.5 25 0.5V0.5Z" fill={bg}/>
<path d="M19.75 19.4474C19.75 22.4507 22.3298 24.8947 25.5 24.8947C28.6702 24.8947 31.25 22.4507 31.25 19.4474C31.25 16.4441 28.6702 14 25.5 14C22.3298 14 19.75 16.4441 19.75 19.4474ZM35.7222 37H37V35.7895C37 31.1181 32.9865 27.3158 28.0556 27.3158H22.9444C18.0122 27.3158 14 31.1181 14 35.7895V37H35.7222Z" fill={color}/>
</svg>


);

export default ProfileSVG;
