import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import "../static/css/GetStarted.css";
import "../static/css/style.css";
import l2 from "../static/img/l2.jpg";
import p2 from "../static/img/p2.jpg";
import MenuBar from "../components/MenuBar";

const GetStarted = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 450);
  const image = isMobile ? p2 : l2;
  const touchStartX = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };
    
    document.title = 'Get Started | Gamez Academy';

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openHome = () => {
      localStorage.setItem("demo", false);
     navigate('/home');  window.location.reload(); window.location.reload();
    };

  return (
    <div
      className="get-started-container"
    >
      <MenuBar />      
      <div className="image-slider">
        <img src={image} alt="slide" className="slide " />
      </div>

      <button
        onClick={openHome}
        className="get-started-button ripple"
      >
        Get Started
      </button>
    </div>
  );
};

export default GetStarted;
