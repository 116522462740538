import { useEffect, useState } from "react"
import { getAllStories } from "../../apiCalls/stories/api_v1_stories"
import { storeToken } from "../../apiCalls/jwt/api_v1_jt";
import { useLocation } from "react-router-dom";
import LoaderSmall from "../animations/LoaderSmall";
import LazyImage from "../LazyImage/LazyImage";

const StoriesList = ({ gameId, isLoggedIn, hanldeLogin }) => {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    // const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            return hanldeLogin();
        }
        async function getStoriesData(Id) {
            let payload = {
                "uuid": Id
            }
            if (location.pathname !== "/") {
                getAllStories(payload).then(res => {
                    if (res.status === 200 && res.data.success === true) {
                        storeToken(res.headers['access_token']);
                        setStories(res.data.data.stories.map(story => story.thumbnail))
                        setTimeout(() => {
                            setLoading(false)
                        }, 700)
                    }

                }).catch(e => {
                    console.log(e)
                })
            }
            else {
                getAllStories().then(res => {
                    if (res.status === 200 && res.data.success === true) {
                        storeToken(res.headers['access_token']);
                        setStories(res.data.data.stories.map(story => story.thumbnail))
                        setTimeout(() => {
                            setLoading(false)
                        }, 700)
                    }
                }).catch(e => {
                    // console.log(e)
                })
            }
        }
        if (location.pathname === "/home") {
            getStoriesData(gameId)
        } else {
            getStoriesData(gameId)
        }
    }, [gameId])


    return (
        <>
            {stories.length > 0 ?
                <>
                    {!loading ?

                        <div style={{ display: 'flex', overflowX: 'scroll', width: '100%', minHeight: "50px", maxHeight: '50px', scrollbarWidth: 'none' }}>
                            {stories.map((story, index) => (
                                <LazyImage key={"key" + index} story={story} stories={stories} index={index} />
                            ))}
                        </div>
                        :
                        <div style={{ display: 'flex', overflowX: 'scroll', width: '100%', maxHeight: '80px', scrollbarWidth: 'none' }}>
                            <LoaderSmall />
                        </div>
                    }
                </>
                : ""}
        </>
    )
}
export default StoriesList