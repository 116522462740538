import axiosClient from "../axiosClient"
export function getAllPodcastsSlider(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    let path = window.location.pathname;
    if (path === "/" || path === "/home") {
        return axiosClient.post('/home/podcasts/slider', JSON.stringify(data));
    } else {
        return axiosClient.post('/podcasts/slider', JSON.stringify(data));
    }
}
export function getAllPodcasts(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    let path = window.location.pathname;
    if (path === "/" || path === "/home") {
        return axiosClient.post('/home/podcasts/all', JSON.stringify(data));
    } else {
        return axiosClient.post('/podcasts/all', JSON.stringify(data));
    }
}
export function getSinglePodcast(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/podcasts/more', JSON.stringify(data));
}
export function podcastViewed(id) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get(`/podcasts/view/${id}`);
}