import { Card, CardMedia, Typography, Box } from "@mui/material";
import React from "react";

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    color: "white",

  },
  media: {
    width: '150px',
    height: '100px',
    marginRight: '16px',
    borderRadius: '15px'
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap:'5px',
  },
};

const GuidezTileCard = ({ img, title, duration }) => {
  return (
    <Card sx={styles.card}>
      <CardMedia component="img" image={img} sx={styles.media} loading="lazy" />
      <Box sx={styles.content}>
        <Typography variant="body1">{title}</Typography>
        {/* <Typography variant="body2">{duration} min</Typography> */}
      </Box>
    </Card>
  );
};

export default GuidezTileCard;
