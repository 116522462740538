import React from "react";
import { useEffect, useRef, useState } from "react";
import guidezIcon from "../static/img/guidez-icon.svg";
import GuidezCard from "../components/podcasts/GuidezCard";
import { getSinglePodcast } from "../apiCalls/podcasts/api_v1_podcasts";
import { storeToken } from "../apiCalls/jwt/api_v1_jt";
import { Link, useLocation } from "react-router-dom";
import GuidezTileCard from "../components/podcasts/GuidezTileCard";

const PodcastDetails = ({ isLoggedIn, hanldeLogin, isMobile }) => {
  const location = useLocation();
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const mainVideoRef = useRef(null);

  const [mainVideo, setMainVideo] = useState({
    video_uuid: new URLSearchParams(window.location.search).get("uuid"),
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Game Guidez | Gamez Academy';
    async function fetchData() {
      let payload = {
        uuid: mainVideo.video_uuid,
      };
      getSinglePodcast(payload)
        .then((res) => {
          if (res.status === 200 && res.data.success === true) {
            storeToken(res.headers["access_token"]);
            // setMainVideo({
            //     title: res.data.data.podcasts[0].title,
            //     video_url: res.data.data.podcasts[0].video
            // })
            setPodcasts(res.data.data.podcasts);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    fetchData();
    setMainVideo({
      video_uuid: location.state.podcast.uuid,
      video_url: location.state.podcast.video,
      vid_thumb: location.state.podcast.thumbnail,
      title: location.state.podcast.title
    })
  }, []);
  function handleBuffering() {
    setIsBuffering(true);
  }
  function handleBufferingComplete() {
    setIsBuffering(false);
  }

  function handleNewVideoPlay(vid_uuid, vid_url, vid_title, vid_thumb) {
    setMainVideo({
      title: vid_title,
      video_url: vid_url,
      video_uuid: vid_uuid,
      vid_thumb: vid_thumb
    });

    let payload = {
      uuid: vid_uuid,
    };
    getSinglePodcast(payload)
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          storeToken(res.headers["access_token"]);
          setPodcasts(res.data.data.podcasts);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // const podcastViewed = (podcast) => {
  //   podcastViewed(podcast.uuid);
  // };


  return (
    <div className="tab">
      <div className="horizontal-header">
        <img src={guidezIcon} alt="Guidez Icon" className="bitez-icon" />
        <h2>Gamez Guides</h2>
      </div>
      <div className={!isMobile ? "podcasts" : ""}>
        <section>
          <GuidezCard
            img={mainVideo.vid_thumb}
            isVideo={true}
            text={mainVideo.title}
            videoUrl={mainVideo.video_url}
            videoRef={mainVideoRef}
            handleBuffering={handleBuffering}
            handleBufferingComplete={handleBufferingComplete}
          />
        </section>
        <br />
        <section>
          <h2>More Guides</h2>
          <div style={isMobile ? { maxHeight: '300px', overflowY: 'scroll' } : {}}>
            {podcasts.map((podcast, i) => (
              <Link style={{ textDecoration: 'none' }} key={i} to={`?uuid=${podcast.uuid}`} onClick={(e) => { handleNewVideoPlay(podcast.uuid, podcast.video, podcast.title, podcast.thumbnail) }}>
                <GuidezTileCard img={podcast.thumbnail} title={podcast.title} duration="Unknown" />
                <br />
              </Link>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PodcastDetails;
