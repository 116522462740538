import React, { useEffect, useRef } from 'react';
import Stories from "react-insta-stories";
import { useNavigate, useLocation } from 'react-router-dom';

function StoryViewer() {
    let location = useLocation();
    const nav = useNavigate();
    const storiesRef = useRef(null);
    const currentIndex = location.state?.currIndex ?? 0;

    useEffect(() => {
        const handleTouchMove = (event) => {
            event.preventDefault();
        };

        const handleMouseDown = (event) => {
            event.preventDefault();
        };

        const storiesElement = storiesRef.current;
        if (storiesElement) {
            storiesElement.addEventListener('touchmove', handleTouchMove, { passive: false });
            storiesElement.addEventListener('mousedown', handleMouseDown, { passive: false });
        }

        return () => {
            if (storiesElement) {
                storiesElement.removeEventListener('touchmove', handleTouchMove);
                storiesElement.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, []);

    try {
        return (
            <div style={{
                width: '100vw',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
            }}>
                <div style={{
                    width: '100vw',
                    height: '100%',
                    maxWidth: '550px',
                    display: 'flex',
                    justifyContent: 'center'
                }} ref={storiesRef}>
                    <Stories
                        defaultInterval={999999}
                        currentIndex={currentIndex}
                        stories={location.state.stories}
                        width={'100%'}
                        height={'100%'}
                        onAllStoriesEnd={() => { nav('/home') }}
                    />
                </div>
            </div>
        );
    } catch (error) {
        console.error("Error loading stories:", error);
        return (
            <div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0
            }}>
                <p>Error loading stories. Please try again later.</p>
            </div>
        );
    }
}

export default StoryViewer;
