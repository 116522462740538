import React, { useEffect } from "react";
import Podcasts from "../components/podcasts/GetPodcasts";
import GamesBites from "../components/gameBites/GameBites";
import gamezTipsIcon from "../static/img/gamezTipsIcon.svg";
import StoriesList from "../components/stories/StoriesList"

const GameDetails = ({ isLoggedIn, hanldeLogin, isMobile }) => {
  const uuid = new URLSearchParams(window.location.search).get("uuid");
  const gameName = new URLSearchParams(window.location.search).get("game")
  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Gamez | Gamez Academy';
  }, [])
  return (
    <>
      <div className="game-header">
        <img width="100%" src={require(`../static/img/dummy/${gameName}_Cover.avif`)} />
        <div>
          <p>{gameName}</p>
        </div>
      </div>
      <div className="tab">
        <header>
          <span className="horizontal-header">
            <img src={gamezTipsIcon} alt="Bitez Icon" className="bitez-icon" />
            <h3>Gamez Tips</h3>
          </span>
          <StoriesList gameId={uuid} isLoggedIn={isLoggedIn} hanldeLogin={hanldeLogin} />
        </header>
        <br />
        <GamesBites isMobile={isMobile} gameId={uuid} />
        <br />
        <Podcasts gameId={uuid} isMobile={isMobile} />
        <br />
      </div>
    </>
  );
};

export default GameDetails;
