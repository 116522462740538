import React, { useEffect, useState } from "react";
import "../static/css/style.css";
import { Grid } from "@mui/material";
import GameCard from "../components/gamez/GameCard";
import { getAllGames } from "../apiCalls/games/api_v1_games";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import searchIcon from "../static/img/search_icon.svg";

const GamesTab = ({ isLoggedIn, hanldeLogin }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredGames, setFilteredGames] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Gamez | Gamez Academy';
    const handleScroll = () => {
      if (window.scrollY > 0) {
      } else {
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getAllGames()
      .then((res) => {
        setGames(res.data.data.games);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const filtered = games.filter((game) =>
      game.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredGames(filtered);
  }, [searchInput, games]);

  function handleSearchInputChange(event) {
    setSearchInput(event.target.value);
  }

  return (
    <div className="tab">
      <header>
        <h2>Choose your favorite Game</h2>
        <p>Find the best guide on your favorite games</p>
      </header>

      <section id="games-section">
        <center>
          <span id='game-search'>
            <input
              className="input"
              type="text"
              name="game-search"
              id=""
              placeholder="Search for Games"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <img src={searchIcon} alt="Search Icon" />
          </span>
        </center>
        <Grid
          container
          spacing={1}
          style={{
            maxWidth: "1200px",
            justifyContent: "left",
            paddingTop: "1em",
          }}
        >
          {filteredGames.map((game, index) => (
            <Grid
              item
              xs={6}
              md={4}
              sm={6}
              lg={4}
              style={{ display: "flex", justifyContent: "center" }}
              key={index}
            >
              <Link
                to={`/game-detail?uuid=${game.uuid}&game=${game.name}`}
              >
                <GameCard
                  img={require(`../static/img/dummy/${game.name}.webp`)}
                  name={game.name}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default GamesTab;
