import React from 'react';

const ShortsSVG = ({ color, bg }) => (
    <svg width="40" height="41" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 0.5C38.8071 0.5 50 11.6929 50 25.5V25.5C50 39.3071 38.8071 50.5 25 50.5V50.5C11.1929 50.5 0 39.3071 0 25.5V25.5C0 11.6929 11.1929 0.5 25 0.5V0.5Z" fill={bg}/>
<path d="M14.5 15.6585C14.5 15.0192 15.0192 14.5 15.6585 14.5H34.3415C34.9808 14.5 35.5 15.0192 35.5 15.6585V34.3415C35.4997 34.6487 35.3775 34.9431 35.1603 35.1603C34.9431 35.3775 34.6487 35.4997 34.3415 35.5H15.6585C15.3512 35.5 15.0566 35.3779 14.8393 35.1607C14.6221 34.9434 14.5 34.6488 14.5 34.3415V15.6585ZM23.3923 20.8175C23.3221 20.7707 23.2405 20.7437 23.1562 20.7396C23.0719 20.7355 22.9881 20.7543 22.9136 20.794C22.8391 20.8338 22.7768 20.8929 22.7334 20.9653C22.6899 21.0376 22.6668 21.1204 22.6667 21.2048V28.7952C22.6668 28.8796 22.6899 28.9624 22.7334 29.0347C22.7768 29.1071 22.8391 29.1662 22.9136 29.206C22.9881 29.2457 23.0719 29.2645 23.1562 29.2604C23.2405 29.2563 23.3221 29.2293 23.3923 29.1825L29.0845 25.3885C29.1483 25.3457 29.2005 25.2879 29.2367 25.2201C29.2728 25.1524 29.2917 25.0768 29.2917 25C29.2917 24.9232 29.2728 24.8476 29.2367 24.7799C29.2005 24.7121 29.1483 24.6543 29.0845 24.6115L23.3923 20.8175Z" fill={color}/>
</svg>

);

export default ShortsSVG;
