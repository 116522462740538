import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { storeToken } from "../../apiCalls/jwt/api_v1_jt";
import { getAllShorts } from "../../apiCalls/shorts/api_v1_shorts";
import { useLocation } from "react-router-dom";
// import Spinner from "../spinner/Spinner";
import GamezBitesCard from "./GamezBitesCard";
import bitezIcon from "../../static/img/ri_video-fill.svg";
import LoaderSmall from "../animations/LoaderSmall";
import LazyShortsImage from "../LazyImage/LazyShortsImage";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const GamesBites = ({ gameId, isMobile }) => {
  const location = useLocation();
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getStoriesData() {
      let payload = {
        uuid: gameId,
      };
      if (location.pathname !== "/home") {
        getAllShorts(payload)
          .then((res) => {
            if (res.status === 200 && res.data.success === true) {
              storeToken(res.headers["access_token"]);
              if (res.data.respCode === "ok") {
                setShorts(res.data.data.shorts);
              }
              setLoading(false);
            }
          })
          .catch((e) => {
            // console.log(e)
          });
      } else {
        getAllShorts()
          .then((res) => {
            if (res.status === 200 && res.data.success === true) {
              storeToken(res.headers["access_token"]);
              if (res.data.respCode === "ok") {
                setShorts(res.data.data.shorts);
              }
              setLoading(false);
            }
          })
          .catch((e) => {
            // console.log(e)
          });
      }
    }
    getStoriesData();
  }, []);

  return (
    <div style={{ minHeight: "376px" }}>
      <span className="horizontal-header">
        <img src={bitezIcon} alt="Bitez Icon" className="bitez-icon" />
        <h3>Gamez Bites</h3>
      </span>
      {loading && (<LoaderSmall />)}
      <Swiper
        spaceBetween={10}
        slidesPerView={isMobile ? 1.7 : 4}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {shorts.length > 0 ?
          <>
            {shorts.map((short, index) => (
              <SwiperSlide key={index}>
                <Link
                  key={index}
                  style={{ textDecoration: 'none' }}
                  to={
                    gameId
                      ? `/shorts?uuid=${gameId}`
                      : `/shorts?uuid=${short.uuid}`
                  }
                >
                  <LazyShortsImage img={short.thumbnail} />
                </Link>
              </SwiperSlide>
            ))}
          </>
          : null}
      </Swiper>
    </div>
  );
};

export default GamesBites;
