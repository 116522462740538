    import { Card, CardMedia, Box, Typography } from '@mui/material'

    const styles = {
        card: {
            border: 'none',
            borderRadius: '15px',
            background:'transparent',
            boxShadow: 'none',
        },
        box: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            color: 'white',
            padding: '10px',
        },
    };

    const GameCard = ({ img, name }) => {
        return (
            <Card sx={styles.card}>
                <Box sx={{ position: 'relative'}}>
                    <CardMedia
                        component="img"
                        image={img}
                        width={181}
                        height={235}
                        loading='lazy'
                    />
                    <Box
                        sx={styles.box}
                    >
                        <h4>{name}</h4>
                    </Box>
                </Box>
            </Card>
        )
    }

    export default GameCard