import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from '../animations/SkeletonLoader';

const LazyImage = ({ story, stories, index }) => {
    const [inView, setInView] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1, // Trigger when 10% of the image is in view
            }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    return (
        <div style={{ position: 'relative', display: 'flex', minWidth: '60px' }} ref={imgRef}>
            {inView && (
                <>
                    {loading && (
                        <SkeletonLoader style={{ width: '100%', width: "50px", height: '100%', borderRadius: '50%' }} />
                    )}
                    <img
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: '10px', width: '50px', visibility: loading ? "hidden" : "visible" }}
                        src={story}
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)} // Optional: handle error state
                        onClick={() => {
                            navigate('/story', { state: { stories: stories, currIndex: index } });
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(LazyImage);
