import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeSVG from '../static/img/bottom-menu/HomeSVG';
import GameSVG from '../static/img/bottom-menu/GameSVG';
import ShortsSVG from '../static/img/bottom-menu/ShortsSVG';
import ProfileSVG from '../static/img/bottom-menu/ProfileSVG';
import "../static/css/bottomMenu.css";

const BottomMenu = () => {
    const [activeButton, setActiveButton] = useState('home');
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

    useEffect(() => {   
        const handleResize = () => {
            setIsMobile(window.innerWidth < 450);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [])

    useEffect(() => {
        if (location.pathname === '/shorts' || location.pathname === '/story') {
            setVisible(false);
        } else {
            setVisible(true);
        }

        switch (location.pathname) {
            case '/home':
                setActiveButton('home')
                break;
            case '/games':
            case '/game-detail':
            case '/podcast-detail':
                setActiveButton('games')
                break;
            case '/shorts':
                setActiveButton('shorts')
                break;
            case '/profile':
            case '/edit-profile':
                setActiveButton('profile')
        }
    }, [location]);

    // const handleScroll = () => {
    //     if (location.pathname != '/shorts' && isMobile) {
    //         const currentScrollPos = window.scrollY
    //         const threshold = 1;
    //         setVisible(prevScrollPos > currentScrollPos || currentScrollPos < threshold);
    //         setPrevScrollPos(currentScrollPos);
    //     }
    // };

    const handleClick = (tabName) => {
        setActiveButton(tabName);
        navigate(`/${tabName}`);
    };

    return (
        <div className={`bottom-menu ${visible ? 'visible' : 'hidden'}`}>
            <ul>
                <li className={`list ${activeButton === 'home' ? 'active' : ''}`} onClick={() => handleClick('home')}>
                    <div>
                        <span className='icon'>
                            <HomeSVG color={activeButton === 'home' ? '#2272A8' : 'white'} bg={activeButton === 'home' ? '#252525' : 'transparent'} />
                        </span>
                        <span className="text">Home</span>
                    </div>
                </li>
                <li className={`list ${activeButton === 'games' ? 'active' : ''}`} onClick={() => handleClick('games')}>
                    <div >
                        <span className='icon'>
                            <GameSVG color={activeButton === 'games' ? '#2272A8' : 'white'} bg={activeButton === 'games' ? '#252525' : 'transparent'} />
                        </span>
                        <span className="text">Games</span>
                    </div>
                </li>
                <li className={`list ${activeButton === 'shorts' ? 'active' : ''}`} onClick={() => handleClick('shorts')}>
                    <div >
                        <span className='icon'>
                            <ShortsSVG color={activeButton === 'shorts' ? '#2272A8' : 'white'} bg={activeButton === 'shorts' ? '#252525' : 'transparent'} />
                        </span>
                        <span className="text">Shorts</span>
                    </div>
                </li>
                <li className={`list ${activeButton === 'profile' ? 'active' : ''}`} onClick={() => handleClick('profile')}>
                    <div >
                        <span className='icon'>
                            <ProfileSVG color={activeButton === 'profile' ? '#2272A8' : 'white'} bg={activeButton === 'profile' ? '#252525' : 'transparent'} />
                        </span>
                        <span className="text">Profile</span>
                    </div>
                </li>
                <div className="indicator"></div>
            </ul>
        </div>
    )
};

export default BottomMenu;
