import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Spinner from "../components/spinner/Spinner";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LikeBtn from "../static/img/LikeBtn.svg";
import LikedBtn from "../static/img/likedBtn.svg";
import ShareBtn from "../static/img/shareBtn.svg";
import ShareModal from "../components/ShareModal";
import { getAllShorts } from "../apiCalls/shorts/api_v1_shorts";
import 'swiper/swiper-bundle.css';

const ShortsTab = ({ isLoggedIn, hanldeLogin }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [like, setLike] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasInteracted, setHasInteracted] = useState(false); // New state variable
  const vidRefs = useRef([]);

  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Game Bites | Gamez Academy';

    async function getShortsData() {
      try {
        const res = await getAllShorts();
        if (res.status === 200 && res.data.success && res.data.respCode === "ok") {
          setShorts(res.data.data.shorts);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching shorts:", error);
        setLoading(false);
      }
    }

    getShortsData();

    return () => {
      vidRefs.current.forEach(video => {
        if (video) {
          video.src = '';
          video.load();
        }
      });
    };
  }, []);

  const fetchMoreReels = async () => {
    try {
      const res = await getAllShorts();
      if (res.status === 200 && res.data.success && res.data.respCode === "ok") {
        setShorts((prevShorts) => [...prevShorts, ...res.data.data.shorts]);
      }
    } catch (error) {
      console.error("Error fetching more reels:", error);
    }
  };

  const toggleShareModal = () => {
    setShareModal(!shareModal);
  };

  const handleToggleLike = () => {
    setLike(!like);
  };

  const pauseAllVideos = () => {
    vidRefs.current.forEach(video => {
      if (video && !video.paused) {
        video.pause();
        setIsPlaying(false);  // Ensure the play button appears
      }
    });
  };

  const handlePlay = (index) => {
    const video = vidRefs.current[index];
    if (video.paused) {
      pauseAllVideos(); // Pause all other videos
      setIsPlaying(true);
      setIsBuffering(true); // Set buffering to true only for the active video
      video.play();
      setHasInteracted(true); // Set interaction to true
    } else {
      setIsPlaying(false);  // Ensure the play button appears when video is paused
      video.pause();
    }
    setShareModal(false);
  };

  const handleSlideChange = (swiper) => {
    pauseAllVideos();
    setActiveIndex(swiper.activeIndex);

    if (shorts.length - swiper.activeIndex <= 2) {
      fetchMoreReels();
    }

    // Reset buffering state for the newly active video
    setIsBuffering(true);

    // Automatically play the video if user has interacted before
    if (hasInteracted) {
      const video = vidRefs.current[swiper.activeIndex];
      if (video) {
        video.play();
        setIsPlaying(true);
      }
    }
  };

  const handleVideoLoad = () => {
    setIsBuffering(false);
  };

  return (
    <div className="shorts-tab" style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="content" onContextMenu={(e) => e.preventDefault()}>
            <div className="shortsContainer" onContextMenu={(e) => e.preventDefault()}>
              <Swiper
                style={{ height: '100%' }}
                direction={'vertical'}
                className="mySwiper"
                onSlideChange={handleSlideChange}
              >
                {shorts.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="videoWrapper"
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <>
                        {isBuffering && activeIndex === index && (
                          <div className="loadingWrapper" style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 10,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          }}>
                            <Spinner />
                          </div>
                        )}
                        <video
                          ref={(el) => (vidRefs.current[index] = el)}
                          className="shortsVideoContent"
                          onClick={() => handlePlay(index)}
                          loop
                          src={item.video}
                          type="video/mp4; codecs=avc1.640028, mp4a.40.2"
                          onContextMenu={(e) => e.preventDefault()}
                          preload="none"
                          playsInline
                          controlsList='nodownload'
                          onPlaying={handleVideoLoad}
                          style={{
                            zIndex: 1,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          }}
                          poster={item.thumbnail}
                        />
                      </>

                      {/* Show play button when video is paused or stopped */}
                      {(!isPlaying || !hasInteracted) && activeIndex === index && (
                        <div
                          className="playBtnDiv"
                          style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 20,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            borderRadius: '50%',
                            padding: '10px',
                            width: '80px',
                            height: '80px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => handlePlay(index)}
                        >
                          <PlayArrowIcon fontSize="large" className="playIcon" />
                        </div>
                      )}

                      {shareModal && (
                        <>
                          <div
                            style={{
                              position: 'fixed',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                              zIndex: 999,
                            }}
                            onClick={toggleShareModal}
                          />
                          <div style={{ zIndex: 1000, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <ShareModal />
                          </div>
                        </>
                      )}

                      <div className="likeNshare"
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          zIndex: 20,
                        }}>
                        <section style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', flexDirection: 'row-reverse' }}>
                          <img
                            src={like ? LikedBtn : LikeBtn}
                            onClick={handleToggleLike}
                            alt="Like Button"
                            style={{ cursor: 'pointer' }}
                          />
                          <p style={{ marginRight: '8px' }}>Like</p>
                        </section>
                        <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                          <img
                            src={ShareBtn}
                            onClick={toggleShareModal}
                            alt="Share Button"
                            style={{ cursor: 'pointer' }}
                          />
                          <p style={{ marginRight: '8px' }}>Share</p>
                        </section>
                      </div>

                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShortsTab;
