import axiosClient from "../axiosClient"

// export function storeToken(data) {
//     console.log("storeToken", data)
//     if ((data !== undefined && data !== null) || data !== "") {
//         console.log("storeToken 2", data)
//         localStorage.setItem('token', data);
//     }
//     return true;
// }

export function storeToken(data) {

    if (data !== null && data !== "") {

        localStorage.setItem('token', data);
    }
    return true;
}

export function getToken(access_code) {
    return axiosClient.post('/jt/gen', JSON.stringify(access_code));
}

export function reLogin() {
    localStorage.removeItem('token');
    localStorage.removeItem('msisdn');
    window.location.href = process.env.REACT_APP_LOGIN_URL;
}

export function verifyToken() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/jt/dt');
}


export function getEncMsiUnsub(data) {
    return axiosClient.post('https://unsubscribe.gamezacademy.pk/api/encrypt', JSON.stringify(data));
}

