import axiosClient from "../axiosClient"
export function getAllStories(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    if (bearerTokenHeader) {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    }
    let path = window.location.pathname;
    if (path === "/" || path === "/home") {
        return axiosClient.post('/home/stories/all', JSON.stringify(data));
    } else {
        return axiosClient.post('/stories/all', JSON.stringify(data));
    }

}
export function getSingleStory(id) {
    let bearerTokenHeader = localStorage.getItem('token');
    if (bearerTokenHeader) {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    }
    return axiosClient.get(`/stories/view/${id}`);
}
// extra fuction remove later.
// export function storyViewed(id){
//     let bearerTokenHeader = localStorage.getItem('token');
//     if(bearerTokenHeader){
//         axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
//     }
//     return axiosClient.get(`/stories/view/${id}`);
// }