import { useLocation } from 'react-router-dom';
import logo from "../static/img/logo.png";
import '../static/css/menubar.css';

const MenuBar = ({isMobile}) => {
    const location = useLocation();
    const isShortsPage = (location.pathname === '/shorts' && isMobile) || (location.pathname === '/story' && isMobile);
    return (
        !isShortsPage && (
            <div id="menubar">
                <img src={logo} alt="Jazz Gamez Academy" />
            </div>
        )
    );
};

export default MenuBar;
