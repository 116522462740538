import { memo, useEffect } from "react";
import BufferingAnimation from "./animations/BufferingAnimation";
import { getProfile } from "../apiCalls/user_profile/api_v1_profile";
import { getEncMsiUnsub } from "../apiCalls/jwt/api_v1_jt";

function Unsub() {
    useEffect(() => {
        async function fetchData() {
            try {
                const cachedProfile = JSON.parse(localStorage.getItem("profileData"));
                let phone;
                if (cachedProfile) {
                    phone = cachedProfile.phone;
                    // return cachedProfile.phone;
                } else {
                    const response = await getProfile(); // Assuming this function is defined and fetches profile data
                    localStorage.setItem('profileData', JSON.stringify(response.data.data));
                    phone = response.data.data.phone;
                }
                let uncData = { mobile: phone, domainName: "jazz_gamezacademy" }
                const encUnsub = await getEncMsiUnsub(uncData);
                return encUnsub.data.data;
            } catch (error) {
                // return window.location.replace("http://lp.gamezacademy.pk/unsubscribe");
            }
        }

        fetchData().then(msisdn => {
            localStorage.removeItem('token');
            localStorage.removeItem('profileData');
            if (msisdn) {
                window.location.replace("https://unsubscribe.gamezacademy.pk/" + msisdn.mobile + "/" + msisdn.domainName);
            } else {
                // window.location.replace("http://lp.gamezacademy.pk/unsubscribe");
            }
        });
    }, []);

    return <main className='page-main mt-60'><BufferingAnimation /></main>;;
}

export default memo(Unsub);