import React from 'react';
import ContentCopyIcon from '../static/img/copyIcon.svg';
import WhatsAppIcon from '../static/img/logos_whatsapp.svg';
import TwitterX from '../static/img/logos_twitterX.svg';
import SnapChat from '../static/img/logos_snapchat.svg';

const styles = {
  shareModal: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#191919',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
  },
  shareOptions: {
    display: 'flex',
    padding: '2em',
    gap: '15px'
  },
  shareOption: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const ShareModal = () => {
  const copyToClipboard = () => {
    var copyText = window.location.href;
    navigator.clipboard.writeText(copyText);
  };

  const shareToWhatsApp = () => {
    const shareableLink = encodeURIComponent(window.location.href);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${shareableLink}`;

    window.open(whatsappUrl, '_blank');
  };

  const shareToX = () => {
      const encodedText = encodeURIComponent("Check out this link: " + window.location.href);
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;
  
      window.open(twitterUrl, '_blank');
  }

  const shareToSnapchat = () => {
    const encodedUrl = encodeURIComponent(window.location.href);
    const snapchatUrl = `snapchat://send?text=Check out this link&url=${encodedUrl}`;

    window.location.href = snapchatUrl;
  };

  return (
    <div style={styles.shareModal}>
      <div style={styles.shareOptions}>
        <span style={styles.shareOption} onClick={copyToClipboard}>
          <img src={ContentCopyIcon} />
          <span>Copy Link</span>
        </span>
        <span style={styles.shareOption} onClick={shareToWhatsApp}>
          <img src={WhatsAppIcon} />
          <span>WhatsApp</span>
        </span>
        <span style={styles.shareOption} onClick={shareToX}>
          <img src={TwitterX} />
          <span>X</span>
        </span>
        <span style={styles.shareOption} onClick={shareToSnapchat}>
          <img src={SnapChat} />
          <span>Snapchat </span>
        </span>
      </div>
    </div>
  );
};

export default ShareModal;
