import React from 'react';
import styles from '../../static/css/animatoin_styles/loaderSmall.module.css';
const LoaderSmall = ({ showLoadingPrmpt = true, promptText = "Loading..." }) => {
    return (
        <>
            <section className={styles.loaderSection}>
                <div className={styles.lds_ring}><div></div><div></div><div></div><div></div></div>
                {
                    showLoadingPrmpt &&
                    <h1 className={styles.loaderSecitionh1}>
                        {promptText}
                    </h1>
                }

            </section>

        </>
    );
}

export default React.memo(LoaderSmall);