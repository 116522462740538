import { Card, CardMedia, Box, Typography } from "@mui/material";
import playBtn from "../../static/img/playBtnSmall.svg";
import pauseBtn from "../../static/img/pause_icon.svg";
import volumeBtn from "../../static/img/volumeBtnSmall.svg";
import React, { useState, useEffect } from "react";
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';

const styles = {
  card: {
    border: "none",
    borderRadius: "15px",
    background: "transparent",
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    color: "white",
  },
  box: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "95%",
    // backgroundColor: "rgba(0, 0, 0, 0.54)",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent)",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "50%",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  vidBox: {
    position: "absolute",
    bottom: 10,
    left: 0,
    // width: "97%",
    color: "white",
    // paddingBottom: "2em",
    padding: 0,
    paddingLeft: "10px",
    display: "flex",
    // alignItems: "flex-end",
    // justifyContent: "space-between",
  },
  button: {
    width: "32px",
    height: "32px",
    marginRight: "8px",
  },
  text: {
    color: "white",
    textStyle: "none",
    textDecoration: "none",
  },
  progressText: {
    lineHeight: 2.5,
  },
  media: {
    width: "100%",
    height: "235px",
  },
  mediaVid: {
    width: "100%",
  },
  mediaDesktop: {
    width: "100%",
    height: "auto",
  },
  boxDesktop: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "98.5%",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent)",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "50%",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    paddingRight: "1em",
  },
  title: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
  },
};

const GuidezCard = ({
  img,
  text,
  isVideo,
  videoUrl,
  videoRef,
  handleBuffering,
  handleBufferingComplete,
}) => {
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 450);
  const [isPlaying, setIsPlaying] = useState(true)
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateTimer = () => {
      if (videoRef.current) {
        const current = videoRef.current.currentTime;
        const duration = videoRef.current.duration;
        const percent = (current / duration) * 100;

        setCurrentTime(formatTime(current));
        setDuration(formatTime(duration));
        setProgress(percent);
      }
    };

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    if (isVideo) {
      videoRef.current.addEventListener("timeupdate", updateTimer);
    }

    return () => {
      if (isVideo && videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", updateTimer);
      }
    };
  }, [isVideo]);

  const handlePlay = () => {
    if (isVideo && videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true)
      } else {
        setIsPlaying(false)
        videoRef.current.pause();
      }
    }
  };

  const HandleSound = () => {
    if (isVideo && videoRef.current) {
      if (videoRef.current.volume === 0) {
        videoRef.current.volume = 1;
      } else {
        videoRef.current.volume = 0;
      }
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const progress = (video.currentTime / video.duration) * 100;
    setProgress(progress);
  };

  const handleVideoDoubleClick = () => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  };

  const handleProgressBarClick = (e) => {


    // const video = videoRef.current;
    // const rect = e.target.getBoundingClientRect();
    // const offsetX = e.clientX - rect.left;
    // const progressBarWidth = rect.width;
    // const percentage = offsetX / progressBarWidth;
    // video.currentTime = video.duration * percentage;

    const vid = videoRef.current;
    const seekbar = document.getElementById("custom-seekbar");
    const offset = seekbar.getBoundingClientRect();
    const left = e.clientX - offset.left;
    const totalWidth = seekbar.offsetWidth;
    const percentage = left / totalWidth;
    const vidTime = vid.duration * percentage;
    vid.currentTime = vidTime;
  };


  return (
    <>
      <Card sx={styles.card} >
        {isVideo ? (
          <div>
            <CardMedia
              component="video"
              controls={false}
              src={videoUrl}
              ref={videoRef}
              playsInline={true}
              onContextMenu={(e) => e.preventDefault()}
              onWaiting={handleBuffering}
              onPlaying={handleBufferingComplete}
              sx={styles.mediaVid}
              onClick={handlePlay}
              onDoubleClick={handleVideoDoubleClick}
              autoPlay={true}
            />
            <div
              className="progress-bar" id="custom-seekbar"
              onClick={handleProgressBarClick}
            >
              <div
                className="progress"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            {/* <div id="custom-seekbar" onClick={handleProgressBarClick}>
              <span id="custom-seekbar-span"></span>
            </div> */}
            {/* <progress  className="progress-bar" max={100} value={progress} /> */}
          </div>
        ) : (
          <CardMedia
            loading="lazy"
            component="img"
            image={img}
            sx={isMobile ? styles.media : styles.mediaDesktop}
          />
        )}
        <Box
          sx={
            isVideo ? styles.vidBox : isMobile ? styles.box : styles.boxDesktop
          }
        >
          <Box>
            <img
              src={!isVideo ? playBtn : !isPlaying ? playBtn : pauseBtn}
              alt="Play Button"
              style={styles.button}
              onClick={handlePlay}
            />
            <img
              src={volumeBtn}
              alt="Volume Button"
              style={styles.button}
              onClick={HandleSound}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            padding: "0px",
            marginBottom: '10px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={styles.progressText} variant="body1">
              {currentTime} / {duration}
            </Typography>
            {isVideo ? <FullscreenTwoToneIcon onClick={handleVideoDoubleClick} /> : <></>}
            {/* {isVideo ? <img src={fullScreen} width={24} height={24} onClick={handleVideoDoubleClick}/> : <></>} */}
          </Box>
        </Box>
      </Card>


      <span style={styles.title}>
        <img
          src={img}
          alt="User Image"
          style={{
            width: "48px",
            height: "48px",
            marginRight: "0.5em",
            borderRadius: "50%",
            marginTop: ".5em",
          }}
        />
        <p style={styles.text}>{text}</p>
      </span>
    </>
  );
};

export default GuidezCard;
