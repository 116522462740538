import React, { useEffect, useState } from "react";
import gamezIcon from "../static/img/gamez-icon.svg";
import { getAllGames } from "../apiCalls/games/api_v1_games";
import { Link } from "react-router-dom";
// import gamezTipsIcon from "../static/img/gamezTipsIcon.svg";
import Grid from "@mui/material/Grid";
import { Swiper, SwiperSlide } from "swiper/react";
import GameCard from "../components/gamez/GameCard";
import StoriesList from "../components/stories/StoriesList";
import GamesBites from "../components/gameBites/GameBites";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import LazyPodcasts from "../components/podcasts/GetPodcasts";

const HomeTab = ({ isLoggedIn, hanldeLogin, isMobile }) => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      return hanldeLogin();
    }
    document.title = 'Home | Gamez Academy';
    getAllGames()
      .then((res) => {
        setGames(res.data.data.games);
      })
      .catch((error) => {
        // Handle error
      });
  }, []);

  return (
    <div className="tab">
      <StoriesList isLoggedIn={isLoggedIn} hanldeLogin={hanldeLogin} />
      <br />
      <GamesBites isMobile={isMobile} />
      <br />
      <LazyPodcasts isMobile={isMobile} />
      <br />
      <div>
        <span className="horizontal-header">
          <img src={gamezIcon} alt="Guidez Icon" className="bitez-icon" />
          <h3>Gamez</h3>
        </span>
        <section className="swiper-container">
          <Swiper slidesPerView={isMobile ? 1.87 : 4}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
          >
            {games && games.length > 0 &&
              games.map((game, index) => (
              <SwiperSlide key={index}>
                <Grid
                  item
                  xs={6}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Link
                    to={`/game-detail?uuid=${game.uuid}&game=${game.name}`}
                    key={index}
                  >
                    <GameCard
                      img={require(`../static/img/dummy/${game.name}.webp`)}
                      name={game.name}
                    />
                  </Link>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
    </div>
  );
};

export default HomeTab;
