import React, { useMemo } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import { storeToken, verifyToken, getToken, reLogin } from "../apiCalls/jwt/api_v1_jt";
import { useEffect, useState } from "react";
import Spinner from './spinner/Spinner.js';

const useAuth = () => {
    let isTokenAvailable = localStorage.getItem("token");
    const location = useLocation();
    const searchParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);
    const [loggedIn, setLoggedIn] = useState(null);
    useEffect(() => {
        const checkAuth = async () => {
            try {
                if (loggedIn === null || loggedIn === false) {
                    if (isTokenAvailable) {
                        const response = await verifyToken();
                        if (response?.status === 200) {
                            setLoggedIn(true)
                            return;
                        } else {
                            const access_code = searchParams.get("a");
                            localStorage.setItem("msisdn", access_code);
                            if (access_code) {
                                const response2 = await getToken({ msisdn: access_code });
                                if (response2?.status === 200 && response2.headers?.['access_token']) {
                                    storeToken(response2.headers['access_token']);
                                    setLoggedIn(true);
                                    return;
                                }
                                else {
                                    setLoggedIn(false);
                                }
                            }
                            setLoggedIn(false);
                        }
                    } else {
                        const access_code = searchParams.get("a");
                        localStorage.setItem("msisdn", access_code);
                        if (access_code) {
                            const response2 = await getToken({ msisdn: access_code });
                            if (response2?.status === 200 && response2.headers?.['access_token']) {
                                storeToken(response2.headers['access_token']);
                                setLoggedIn(true);
                                return;
                            }
                            else {
                                setLoggedIn(false);
                            }
                        } else {
                            const msi = localStorage.getItem("msisdn");
                            const msi_res = await getToken({ msisdn: msi });
                            if (msi_res?.status === 200 && msi_res.headers?.['access_token']) {
                                storeToken(msi_res.headers['access_token']);
                                setLoggedIn(true);
                                return;
                            }
                            else {
                                setLoggedIn(false);
                            }
                            if (window.location.pathname === '/') {
                                return;
                            }
                            return reLogin();
                        }
                    }
                }
            } catch (error) {
                if (error.response?.status === 401) {

                    const access_code = searchParams.get("a");
                    localStorage.setItem("msisdn", access_code);
                    if (access_code) {
                        const response2 = await getToken({ msisdn: access_code });
                        if (response2?.status === 200 && response2.headers?.['access_token']) {
                            storeToken(response2.headers['access_token']);
                            setLoggedIn(true);
                            return;
                        }
                        else {
                            const msi = localStorage.getItem("msisdn");
                            const msi_res = await getToken({ msisdn: msi });
                            if (msi_res?.status === 200 && msi_res.headers?.['access_token']) {
                                storeToken(msi_res.headers['access_token']);
                                setLoggedIn(true);
                                return;
                            }
                            else {
                                setLoggedIn(false);
                            }
                        }
                    }
                    if (window.location.pathname === '/home' || window.location.pathname === '/') {
                        return;
                    }
                    return reLogin();
                }

            }
        };
        checkAuth();
    }, [loggedIn, location.pathname, isTokenAvailable, searchParams]);
    return loggedIn
}


const ProtectedRoutes = () => {

    const isAuth = useAuth();
    if (isAuth == null && window.location.pathname == '/home' || window.location.pathname == '/') {
        return <Outlet />;
    }
    return isAuth ? <Outlet /> :
        <div className="h-[80vh] flex justify-center items-center">
            <Spinner />
        </div>

}


export default React.memo(ProtectedRoutes);