import React from 'react';

const HomeSVG = ({ color, bg }) => (
    <svg width="38" height="39" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 0.5C38.8071 0.5 50 11.6929 50 25.5V25.5C50 39.3071 38.8071 50.5 25 50.5V50.5C11.1929 50.5 0 39.3071 0 25.5V25.5C0 11.6929 11.1929 0.5 25 0.5V0.5Z" fill={bg} />
        <path d="M38.0098 24.3332L25.9424 12.2746C25.8555 12.1876 25.7523 12.1185 25.6387 12.0714C25.5251 12.0243 25.4033 12 25.2803 12C25.1573 12 25.0355 12.0243 24.9219 12.0714C24.8083 12.1185 24.7051 12.1876 24.6182 12.2746L12.5508 24.3332C12.1992 24.6848 12 25.1623 12 25.6604C12 26.6946 12.8408 27.5354 13.875 27.5354H15.1465V36.1399C15.1465 36.6584 15.5654 37.0774 16.084 37.0774H23.4053V30.5149H26.6865V37.0774H34.4766C34.9951 37.0774 35.4141 36.6584 35.4141 36.1399V27.5354H36.6855C37.1836 27.5354 37.6611 27.3391 38.0127 26.9846C38.7422 26.2522 38.7422 25.0656 38.0098 24.3332Z" fill={color} />
    </svg>

);

export default HomeSVG;
