import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./static/css/style.css";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import MainScreen from './views/MainScreen';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <MainScreen />
);

