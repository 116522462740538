import React, { useEffect, useRef, useState } from "react";
import profile_img_ph from "../static/img/profile-tab/player_img.png";
import { getProfile, editProfile } from "../apiCalls/user_profile/api_v1_profile";
import { reLogin, storeToken } from "../apiCalls/jwt/api_v1_jt";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import editIcon from "../static/img/edit_icon.svg";

const EditProfileTab = ({ isLoggedIn, handleLogin }) => {
  const [profileData, setProfileData] = useState(null);
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (!isLoggedIn) {
      return handleLogin();
    }
    document.title = 'Edit Profile | Gamez Academy';

    async function fetchData() {
      try {
        const cachedProfile = JSON.parse(localStorage.getItem("profileData"));
        if (cachedProfile) {
          setProfileData(cachedProfile);
          setFormData({
            username: cachedProfile.username,
            firstname: cachedProfile.name.split(' ')[0],
            lastname: cachedProfile.name.split(' ')[1],
            city: cachedProfile.city,
            country: cachedProfile.country,
            bio: cachedProfile.bio,
            gender: cachedProfile.gender,
            email: cachedProfile.email,
            dateofbirth: cachedProfile.dateOfBirth,
            social_facebook: cachedProfile.social_facebook,
            social_twitter: cachedProfile.social_twitter,
            social_skype: cachedProfile.social_skype,
            social_youtube: cachedProfile.social_youtube
          });
          setTimeout(async () => {
            const response = await getProfile();
            localStorage.setItem(
              "profileData",
              JSON.stringify(response.data.data)
            );
            setProfileData(response.data.data);
            setFormData({
              username: response.data.data.username,
              firstname: response.data.data.name.split(' ')[0],
              lastname: response.data.data.name.split(' ')[1],
              city: response.data.data.city,
              country: response.data.data.country,
              bio: response.data.data.bio,
              gender: response.data.data.gender,
              email: response.data.data.email,
              dateofbirth: response.data.data.dateOfBirth,
              social_facebook: response.data.data.social_facebook,
              social_twitter: response.data.data.social_twitter,
              social_skype: response.data.data.social_skype,
              social_youtube: response.data.data.social_youtube
            });
          }, 3000);
        } else {
          const response = await getProfile();
          localStorage.setItem(
            "profileData",
            JSON.stringify(response.data.data)
          );
          setProfileData(response.data.data);
          console.log(response.data.data);
          setFormData({
            username: response.data.data.username,
            firstname: response.data.data.name.split(' ')[0],
            lastname: response.data.data.name.split(' ')[1],
            city: response.data.data.city,
            country: response.data.data.country,
            bio: response.data.data.bio,
            gender: response.data.data.gender,
            email: response.data.data.email,
            dateofbirth: response.data.data.dateOfBirth,
            social_facebook: response.data.data.social_facebook,
            social_twitter: response.data.data.social_twitter,
            social_skype: response.data.data.social_skype,
            social_youtube: response.data.data.social_youtube
            
          });
          setProfileImage(response.data.data.profileImage);
        }
        setLoading(false);
      } catch (error) {
        return reLogin();
      }
    }

    fetchData();
  }, [isLoggedIn, handleLogin]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const {
      credits,
      error,
      permission,
      phone,
      success,
      name,
      joinDate,
      message,
      profileImage,
      dateOfBirth, 
      ...filteredFormData
    } = formData;
  
    filteredFormData.dateofbirth = formData.dateofbirth;
    filteredFormData.firstname = formData.firstname;

    // console.log(filteredFormData);
  
    editProfile(filteredFormData)
      .then((response) => {
        storeToken(response.headers['access_token']);
        localStorage.removeItem('profileData');
        submitButtonRef.current.disabled = false;
        navigate("/profile");
      })
      .catch(error => {
        submitButtonRef.current.disabled = false;
      });
  };

  return (
    <div className="tab">
      <header>
        <div className="profile-container">
          <div className="profile-icon">
            <img className="prof-img" src={profileImage ? profileImage : profile_img_ph} alt="Profile Icon" />
          </div>
          <img src={editIcon} alt="Edit Icon" />
        </div>
      </header>
      <form ref={formRef} onSubmit={handleSubmit}>
        <section id="other-info">
          <h2>Edit Info</h2>
          <div className="info-table">
            <table>
              <tbody>
                <tr>
                  <td><b>Username:</b></td>
                  <td>
                    <input required type="text" name="username" id="username" onChange={handleChange} defaultValue={formData?.username || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>First Name:</b></td>
                  <td>
                    <input required type="text" name="firstname" id="firstname" onChange={handleChange} defaultValue={formData?.firstname || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Last Name:</b></td>
                  <td>
                    <input required type="text" name="lastname" id="lastname" onChange={handleChange} defaultValue={formData?.lastname || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>City:</b></td>
                  <td>
                    <input required type="text" name="city" id="city" onChange={handleChange} defaultValue={formData?.city || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Country:</b></td>
                  <td>
                    <input required type="text" name="country" id="country" onChange={handleChange} defaultValue={formData?.country || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Bio:</b></td>
                  <td>
                    <input required type="text" name="bio" id="bio" onChange={handleChange} defaultValue={formData?.bio || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Gender:</b></td>
                  <td>
                    <Select
                      style={{
                        color: "white",
                        borderColor: "white",
                        backgroundColor: "#414141",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={formData?.gender || ''}
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td><b>Email:</b></td>
                  <td>
                    <input required type="email" name="email" id="email" onChange={handleChange} defaultValue={formData?.email || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Date of Birth:</b></td>
                  <td>
                    <input required
                      style={{
                        backgroundColor: "#414141",
                        color: "white",
                        padding: "5px",
                      }}
                      type="date"
                      name="dateofbirth"
                      id="dateofbirth"
                      
                      onChange={handleChange}
                      value={formData.dateofbirth || ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td><b>Facebook Link:</b></td>
                  <td>
                    <input required type="text" name="facebook" id="social_facebook" onChange={handleChange} defaultValue={formData?.social_facebook || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Twitter Link:</b></td>
                  <td>
                    <input type="text" name="twitter" id="social_twitter" onChange={handleChange} defaultValue={formData?.social_twitter || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Skype Link:</b></td>
                  <td>
                    <input type="text" name="skype" id="social_skype" onChange={handleChange} defaultValue={formData?.social_skype || ''} className="input" />
                  </td>
                </tr>
                <tr>
                  <td><b>Youtube Link:</b></td>
                  <td>
                    <input type="text" name="youtube" id="social_youtube" onChange={handleChange} defaultValue={formData?.social_youtube || ''} className="input" />
                  </td>
                </tr>
              </tbody>
            </table>
            <center>
              <button className="unsub-btn ripple" ref={submitButtonRef} type="submit">
                Submit
              </button>
            </center>
          </div>
        </section>
      </form>
      <br />
      <center>
        <button
          className="unsub-btn ripple"
          onClick={() => {
            navigate("/unsubscribe");
          }}
        >
          Unsubscribe
        </button>
      </center>
    </div>
  );
};

export default EditProfileTab;
